/*Se utiliza en el Footer e Inversion */
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("./assets/fonts/Gotham/Gotham-Bold.otf") format("truetype");
}
/* Se utiliza en todos los componentes excepto Imagenes Grid*/
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("./assets/fonts/Gotham/Gotham-Medium.otf") format("truetype");
}
/*Se utiliza en Beneficios y Header*/
@font-face {
  font-family: "Gotham-book";
  src: local("Gotham-book"),
    url("./assets/fonts/Gotham/GothamBook.ttf") format("truetype");
}
/* Se utiliza en Todavia, Inversion y Ganar*/
@font-face {
  font-family: "Gotham-light";
  src: local("Gotham-light"),
    url("./assets/fonts/Gotham/GothamLight.ttf") format("truetype");
}
body {
  margin: 0;
}
