@import "../vars.scss";
.calendly-local {
  background: linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
  height: 98vh;
  padding: 2em 0em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //iphone 5
  @media (max-width: 320px) {
  height: 233vh;
  }
  //moto g4, galaxy s5
  @media (max-width: 360px) and (min-width: 325px) {
    height: 210vh;
  }
  //iphone x
  @media (max-width: 375px) and (min-width: 365px) and (max-height: 812px) {
 height: 162vh;
  }
  //iphone 6 7 y 8
  @media (max-width: 375px) and (min-width: 365px) and (max-height: 670px) {
   height: 197vh;
  }
  //pixel 2, pixel 2 xl
  @media (max-width: 415px) and (min-width: 376px) {
    height: 177vh;
  }
  //ipad
  @media (max-width: 1000px) and (min-width: 750px) {
    height: 125vh;
  }
  //ipad-pro
  @media (max-width: 1300px) and (min-width: 999px) {
    height: 100vh;
  }
  //surface-duo
  @media (max-width: 541px) and (min-width: 538px) {
    height: 178vh;
  }
  h3 {
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    max-width: 59.5%;
    color: white;
    margin: 0em;
    @media ($iphone8plus) {
      font-size: 20px;
      line-height: 22px;
    }
    @media ($ipad) {
      max-width: 85%;
      margin: 1em 0 0.22em 0;
    }
    @media ($display4k) {
      font-size: 40px;
      line-height: 42px;
    }
  }
  .calendly-inner {
    height: 85%;
    min-width: 80%;
    //all the mobile devices
    @media (max-width: 1200px) {
      height: 100%;
      padding: 2em 0em 0em;
    }
  }
}
