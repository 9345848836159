@import "../vars.scss";
.component-inversion {
  background-image: url("../../assets/img/inversionbackground.png"),
    linear-gradient(90deg, #243e84 4.79%, rgba(30, 156, 172, 0.4) 59.86%);
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 97vh;
  color: #ffffff;
  @media ($iphone8plus) {
    background-position-x: 41%;
    background-image: url("../../assets/img/inversionbackground.png"),
      linear-gradient(360deg, #243e84 25.79%, #fff0 59.86%);
    background-blend-mode: multiply;
  }
  .component-second {
    min-height: 97vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 5em;
    @media ($iphone8plus) {
      padding-left: 2em;
      justify-content: flex-end;
    }
    a {
      img {
        max-height: 100px;
        margin: 100px 0 20px 0;
        @media ($display4k) {
          min-height: 170px;
        }
        @media ($iphone8plus) {
          display: none;
        }
      }
    }
    h4 {
      max-width: 36%;
      font-family: "Gotham-medium";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
      margin: 10px 0;

      @media ($display4k) {
        font-size: 52px;
        line-height: 54px;
      }
      @media ($ipad) {
        max-width: 55%;
        font-weight: 325;
        font-size: 16px;
        font-family: "Gotham-light";
      }
      @media ($iphone8plus) {
        max-width: 85%;
        margin-top: 5px;
        margin-bottom: 2.5em;
        &.not-child {
          display: none;
        }
      }
    }
    h3 {
      max-width: 35%;
      margin: 5px 0;
      font-family: "Gotham-bold";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 43px;
      text-transform: uppercase;
      @media ($display4k) {
        font-size: 72px;
        line-height: 74px;
      }
      @media ($ipad) {
        max-width: 55%;
        font-size: 24px;
        line-height: 29px;
      }
      @media ($iphone8plus) {
        max-width: 85%;
        margin-top: 0;
        &.not-child {
          display: none;
        }
      }
      &.not-high {
        display: none;
        @media ($iphone8plus) {
          display: inline;
        }
      }
    }
  }
}
