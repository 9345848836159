@import "../vars.scss";

.beneficios-component {
  background-image: url("../../assets/img/beneficiospersonal.png"),
    linear-gradient(90deg, #243e84 4.55%, rgba(30, 156, 172, 0.2) 95.03%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ($ipad) {
    background: none;
    flex-direction: column;
  }
  .image-component {
    display: none;
    @media ($ipad) {
      display: inline;
      min-height: 195px;
      background-image: url("../../assets/img/beneficiospersonal.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
    }
  }
  .cards-component {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    @media ($ipad) {
      background: linear-gradient(180deg, #243e84 4.79%, #1e9cac 59.86%);
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media ($display4k) {
      height: 43vh;
    }
    .beneficios-card {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      max-width: 26%;
      @media ($ipad) {
        max-width: 90%;
      }

      &.primera {
        margin: 0 4.5%;
        @media ($ipad) {
          margin: 0;
          margin: 20% 0;
        }
      }
      &.segunda {
        @media ($ipad) {
          margin-bottom: 10%;
        }
      }
      .first-card {
        background: #ffffff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        border-radius: 30px 30px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .image-card {
          background: #ffffff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          margin-top: -15%;
          @media ($display4k) {
            margin-top: -7%;
          }
          img {
            padding: 24px 27px;
            height: 48px;
          }
        }
        h4 {
          font-family: "Gotham-medium";
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          color: #243e84;
          @media ($display4k) {
            font-size: 48px;
            line-height: 50px;
          }
        }
      }
      .inferior-card {
        ul {
          margin: 0.3em;
          padding: 25px 25px 0 25px;
          list-style-type: none;
          li {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            p {
              margin: 4px 5px 10px 10px;
              font-family: "Gotham-book";
              font-style: normal;
              font-weight: 325;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              @media ($display4k) {
                font-size: 40px;
                line-height: 45px;
                margin: 10px 5px 10px 10px;
              }
            }
          }
        }
      }
    }
  }
}
