$iphone8plus: "max-width: 450px";
$ipad: "max-width:780px";
$display4k: "min-width:2000px";
button {
  border: none;
  outline: none;
  &.focus {
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
}
a {
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap");
$roboto: "Roboto", sans-serif;
$movil: 920px;
