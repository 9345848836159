@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

@import "../vars.scss";

$animationSpeed: 50s;
$animationSpeed2: 43s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 12));
  }
}

@keyframes bscroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 13));
  }
}

@keyframes scrollm {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-90px * 12));
  }
}

@keyframes bscrollm {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-90px * 13));
  }
}

// Styling

.clients-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: all 1.2s ease-in-out;

  .clients-title {
    top: 0;
    width: 100%;
    text-align: center;
    margin: 25px auto 20px auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      & {
        width: 90%;
        margin: 0 auto;
      }
    }
    h4,
    h5 {
      margin: 3em 0 0 0;
      max-width: 66.5%;
      font-family: "Gotham-medium";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      color: #243e84;
      @media ($ipad) {
        line-height: 34px;
        max-width: 97%;
        font-size: 18px;
        line-height: 24px;
        margin: 0.7em 0 0 0;
      }
      @media ($display4k) {
        font-size: 52px;
        line-height: 56px;
      }
      &.thiny {
        margin: 0.3em 0 2em 0;
        font-size: 22px;
        line-height: 26px;
        font-family: "Gotham-light";
        @media ($ipad) {
          font-size: 16px;
          line-height: 24px;
          margin: 0.5em 0 1.5em 0;
        }
        @media ($display4k) {
          font-size: 42px;
          line-height: 42px;
          margin: 1em 0 1em 0;
        }
      }
    }
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    background-size: 100vw;
    opacity: 0.04;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
      opacity: 0.3;
    }
  }
  .sliders-container {
    width: 100%;
    @media (max-width: $movil) {
      & {
        margin-bottom: 30px;
      }
    }
  }
  .slider {
    background: transparent;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 86%;
    transition: all 1.2s ease-in-out;
    @media (max-width: $movil) {
      & {
        width: 100%;
      }
    }
    &:first-of-type {
      margin-bottom: 70px;
      @media (max-width: $movil) {
        & {
          margin: 0;
        }
      }
    }
    &:last-of-type {
      margin-bottom: 115px;
      @media (max-width: $movil) {
        & {
          margin: 0;
        }
      }
    }
    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 105px;
      position: absolute;
      width: 200px;
      z-index: 2;
      transition: all 1.2s ease-in-out;
      @media (max-width: $movil) {
        & {
          width: 50px;
        }
      }
      [data-theme="dark"] & {
        background: linear-gradient(to right, #202534 0%, #20253400 100%);
      }
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(200px * 24);
      @media (max-width: 768px) {
        & {
          animation: scrollm $animationSpeed linear infinite;
          width: calc(90px * 24);
        }
      }
    }

    .slide-track2 {
      animation: bscroll $animationSpeed2 linear infinite;
      display: flex;
      width: calc(200px * 26);
      @media (max-width: 768px) {
        & {
          animation: bscrollm $animationSpeed linear infinite;
          width: calc(90px * 26);
        }
      }
    }

    .slide {
      height: 110px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      transition: all 0.2s ease-in-out;
      @media (max-width: 768px) {
        & {
          width: 70px;
          height: 120px;
          margin: 0 10px;
        }
      }
      span {
        [data-theme="dark"] & {
          filter: invert(00%) grayscale(100%) brightness(100%) contrast(10000)
            invert(100%);
          transition: all 0.2s ease-in-out;
        }
        @media (max-width: 768px) {
          & {
            width: 100%;
            max-height: 80px;
          }
        }
      }

      .bolt1alfa {
        content: url("./../../assets/img/clients/bolt1alfa.png");
      }

      .bolt2foresta {
        content: url("./../../assets/img/clients/bolt2foresta.png");
      }

      .bolt15gaston {
        content: url("./../../assets/img/clients/bolt15gaston.png");
      }
      .bolt3gloria {
        content: url("./../../assets/img/clients/bolt3gloria.png");
      }
      .bolt7probc {
        content: url("./../../assets/img/clients/bolt7probc.png");
      }
      .bolt6villa {
        content: url("./../../assets/img/clients/bolt6villa.png");
      }
      .bolt4carmona {
        content: url("./../../assets/img/clients/bolt4carmona.png");
      }
      .bolt5marsa {
        content: url("./../../assets/img/clients/bolt5marsa.png");
      }
      .bolt8eljuego {
        content: url("./../../assets/img/clients/bolt8eljuego.png");
      }

      .bolt18ceo {
        content: url("./../../assets/img/clients/bolt18ceo.png");
      }
      .bolt9lapaloma {
        content: url("./../../assets/img/clients/bolt9lapaloma.png");
      }
      .bolt1alfa {
        content: url("./../../assets/img/clients/bolt1alfa.png");
      }
      .bolt21cfe {
        content: url("./../../assets/img/clients/bolt21cfe.png");
      }
      .bolt19volkswagen {
        content: url("./../../assets/img/clients/bolt19volkswagen.png");
      }
      .terragaucha {
        content: url("./../../assets/img/clients/bolt1alfa.png");
      }
      .bolt17madeira {
        content: url("./../../assets/img/clients/bolt17madeira.png");
      }
      .bolt20martinez {
        content: url("./../../assets/img/clients/bolt20martinez.png");
      }
      .bolt16crepe {
        content: url("./../../assets/img/clients/bolt16crepe.png");
      }
      .bolt10pamen {
        content: url("./../../assets/img/clients/bolt10pamen.png");
      }
      .bolt11agropecuaria {
        content: url("./../../assets/img/clients/bolt11agropecuaria.png");
      }
      .bolt12enyog {
        content: url("./../../assets/img/clients/bolt12enyog.png");
      }
      .bolt13monterreal {
        content: url("./../../assets/img/clients/bolt13monterreal.png");
      }
      .bolt14qualitas {
        content: url("./../../assets/img/clients/bolt14qualitas.png");
      }
      .bolt22yog {
        content: url("./../../assets/img/clients/bolt22yog.png");
      }
    }
  }
}
