@import "../vars.scss";
.float-whatsapp {
  background-color: none;
  z-index: 9999999;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 10px;
  left: 14px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  @media ($display4k) {
    min-width: 140px;
    min-height: 140px;
    left: 25px;
  }
  .imagen-whatsapp {
    width: 60px;
    height: 60px;
    @media ($display4k) {
      min-width: 140px;
      min-height: 140px;
    }
  }
}
