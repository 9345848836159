@import "../vars.scss";
.imagenesgrid {
  min-height: 98vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media ($ipad) {
    grid-template-columns: repeat(2, 1fr);
  }
  .imafe {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &.uno {
      background-image: url("../../assets/img/uno.png");
    }
    &.dos {
      background-image: url("../../assets/img/dos.png");
    }
    &.tres {
      background-image: url("../../assets/img/tres.png");
    }
    &.cuatro {
      background-image: url("../../assets/img/cuatro.png");
    }
    &.cinco {
      background-image: url("../../assets/img/cinco.png");
    }
    &.seis {
      background-image: url("../../assets/img/seis.png");
    }
    &.siete {
      background-image: url("../../assets/img/siete.png");
    }
    &.ocho {
      background-image: url("../../assets/img/ocho.png");
    }
  }
}
