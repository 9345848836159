@import "../vars.scss";
.footer-component {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 1em 0 1em 0;
  @media ($ipad) {
    flex-direction: column;
  }
  &.focus {
    cursor: pointer;
  }
  a {
    img {
      @media ($ipad) {
        margin: 2em 0 0em 0;
      }
    }
  }
  a {
    h5 {
      font-family: "Gotham-bold";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #333333;
      @media ($ipad) {
        margin: 2.5em 0 1em 0;
      }
    }
    h4 {
      font-family: "Gotham-medium";
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #243e84;
      @media ($ipad) {
        margin: 1em 0 1.5em 0;
      }
    }
  }
  .fo-logos {
    a {
      .footer-redes {
        margin: 0 1em 0 1em;
        @media ($ipad) {
          margin: 0 1em 1.5em 1em;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.03);
        }
      }
    }
  }
}
