@import "../vars.scss";
.header-component {
  background: url("../../assets/img/header.png"),
    linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  top: 0;
  a {
    img {
      &.logotipoheader {
        position: absolute;
        left: 10%;
        top: 10%;
        @media ($iphone8plus) {
          left: 8%;
          height: 5.5%;
          top: 7%;
        }
        @media ($display4k) {
          height: 5%;
          left: 6%;
        }
      }
    }
  }
  h1 {
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    max-width: 35%;
    margin-top: 5%;
    @media ($ipad) {
      max-width: 80%;
      line-height: 32px;
      font-size: 24px;
    }
    @media ($iphone8plus) {
      font-size: 24px;
      text-align: left;
      line-height: 32px;
      max-width: 59%;
      align-self: flex-start;
      margin-left: 10%;
      margin-top: 30%;
    }
    @media ($display4k) {
      line-height: 80px;
      font-size: 72px;
    }
  }
  .pago-inicial {
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    h2 {
      font-family: "Gotham-medium";
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      text-transform: uppercase;
      color: #243e84;
      margin: 0.8em 30px 0em;
      @media ($ipad) {
        font-size: 24px;
        line-height: 30px;
      }
      @media ($display4k) {
        font-size: 62px;
        line-height: 64px;
      }
    }
    h4 {
      font-family: "Gotham-medium";
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 30px;
      text-transform: uppercase;
      color: #243e84;
      margin: 0em 0.5em 1em;
      @media ($ipad) {
        font-size: 14px;
        line-height: 30px;
      }
      @media ($display4k) {
        font-size: 28px;
        line-height: 48px;
      }
    }
  }
  .reloj {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0em;
    h4 {
      font-family: "Gotham-book";
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      margin: 1.5em 0.5em 1em;
      text-transform: uppercase;
      @media ($ipad) {
        font-size: 14px;
        //line-height: 24px;
      }
      @media ($display4k) {
        font-size: 28px;
        line-height: 48px;
      }
    }
  }
  .component-counter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;
    .count-days {
      .counter {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 8.18182px;
        padding: 15px 15px;
        min-height: 45px;
        min-width: 45px;
        @media ($ipad) {
          padding: 10px 10px;
          min-width: 42px;
          min-height: 42px;
        }
        @media ($iphone8plus) {
          min-width: 30px;
          min-height: 30px;
        }
        @media ($display4k) {
          min-width: auto;
          min-height: auto;
        }
        h5 {
          font-family: "Gotham-medium";
          font-style: normal;
          font-weight: bold;
          font-size: 29.4545px;
          line-height: 25px;
          text-align: center;
          color: #243e84;
          margin: 0;
          @media ($ipad) {
            font-size: 24px;
            line-height: 20px;
          }
          @media ($display4k) {
            font-size: 58px;
            line-height: 68px;
          }
        }
      }
      p {
        font-family: "Gotham-medium";
        font-style: normal;
        font-weight: 350;
        font-size: 13.0909px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        @media ($iphone8plus) {
          font-size: 14px;
          line-height: 20px;
        }
        @media ($display4k) {
          font-size: 28px;
          line-height: 48px;
        }
      }
    }
  }
}
