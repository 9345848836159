@import "../vars.scss";
.proceso {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-family: "Gotham-medium";
  text-align: center;
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #243e84;
    margin-top: 2.5em;
  }
  .proceso-elements {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media ($ipad) {
      display: flex;
      flex-direction: column;
    }
    .organization {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        &.icono-paso {
          height: 64px;
          margin-top: 2em;
          @media ($ipad) {
            margin-top: 1em;
          }
        }
        &.icono-check {
          margin: 10px 0px;
        }
      }
      h5 {
        max-width: 60%;
        margin: 10px 0 5em 0;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #243e84;
        @media ($ipad) {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}
