@import "../vars.scss";
.component-lista {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #243e84 0%, #1e9cac 100%),
    linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
  @media ($ipad) {
    min-height: 75vh;
  }
  @media ($iphone8plus) {
    min-height: 100vh;
  }
  @media ($display4k) {
    min-height: 75vh;
  }
  h3 {
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    max-width: 59.5%;
    color: white;
    margin: 1em 0 2em 0;
    @media ($iphone8plus) {
      font-size: 20px;
      line-height: 22px;
    }
    @media ($ipad) {
      max-width: 85%;
      margin: 2em 0 2em 0;
    }
    @media ($display4k) {
      font-size: 40px;
      line-height: 42px;
    }
  }
  .formulario {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 2em 4em 1em 4em;
    width: 70%;
    margin-bottom: 2em;
    @media ($ipad) {
      width: 80%;
      padding: 2em 2.3em 1em 2.3em;
      margin-bottom: 1.5em;
    }
    @media ($iphone8plus) {
      border-radius: 0px;
      margin-bottom: 0;
    }
    iframe {
      width: 100% !important;
    }
    .cargando {
      font-family: "Gotham-medium";
      font-size: 20px;
      color: #243e84;
    }
  }
}
