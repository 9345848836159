@import "../vars.scss";
.ganar-component {
  display: flex;
  min-height: 384px;
  width: 100%;
  color: #ffffff;
  font-family: "Gotham-medium";
  @media ($display4k) {
    min-height: 768px;
  }
  @media ($ipad) {
    flex-direction: column;
  }
  .blue {
    min-width: 50%;
    position: relative;
    @media ($ipad) {
      min-width: 100%;
    }
    &.left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background: linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
      background-blend-mode: multiply;
      @media ($ipad) {
        min-height: 70vh;
        align-items: center;
      }
      a {
        img {
          padding-left: 91px;
          position: absolute;
          top: 14%;
          @media ($display4k) {
            height: 9%;
          }
          @media ($ipad) {
            height: 54px;
            position: relative;
            padding-left: 0;
          }
        }
      }

      h3 {
        padding-left: 91px;
        font-style: normal;
        font-weight: bold;
        margin: 0;
        @media ($ipad) {
          text-align: center;
        }
        &.bigone {
          margin: 50px 0 5px 0;
          font-size: 36px;
          line-height: 36px;
          max-width: 65%;
          @media ($display4k) {
            font-size: 72px;
            line-height: 74px;
            max-width: 55%;
          }
          @media ($ipad) {
            font-size: 24px;
            line-height: 26px;
            padding-left: 0;
            max-width: 85%;
          }
        }
        &.litleone {
          margin: 0px 0 5px 0;
          font-size: 24px;
          line-height: 26px;
          @media ($display4k) {
            font-size: 48px;
            line-height: 50px;
          }
          @media ($ipad) {
            font-size: 24px;
            line-height: 26px;
            font-family: "Gotham-light";
            padding-left: 0;
            max-width: 70%;
          }
        }
      }
    }
    &.right {
      background-image: url("../../assets/img/ganarequipo.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media ($ipad) {
        min-height: 199px;
      }
    }
  }
}
