@import "../vars.scss";
.grafica-comparativa {
  background: url("../../assets/img/comparativabackground.png"),
    linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  h4,
  h5 {
    margin: 3em 0 0 0;
    max-width: 66.5%;
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    @media ($ipad) {
      line-height: 34px;
      max-width: 86%;
    }
    @media ($display4k) {
      font-size: 52px;
      line-height: 56px;
    }
    &.thiny {
      margin: 1em 0 2em 0;
      font-size: 20px;
      line-height: 26px;
      font-family: "Gotham-light";
      @media ($ipad) {
        margin: 1em 0 1.5em 0;
      }
      @media ($display4k) {
        font-size: 42px;
        line-height: 42px;
        margin: 1em 0 1em 0;
      }
    }
  }

  .image-comparativa {
    max-height: 531px;
    margin: 0 0 2.5em 0;
    @media ($ipad) {
      max-height: 200px;
    }
    @media ($display4k) {
      min-height: 1000px;
    }
  }
}
