@import "../vars.scss";
.buttonz {
  min-height: 45vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  a {
    max-width: 23%;
    width: 23%;
    @media ($ipad) {
      max-width: 85%;
      width: 85%;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      color: #ffffff;
      font-family: "Gotham-medium";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      margin: 1.8em auto;
      width: 100%;
      height: 34px;
      padding: 9px 50px;
      @media ($ipad) {
        padding: 9px 0;
      }
      img {
        margin-left: 1em;
        @media ($ipad) {
          margin-left: 0.5em;
        }
      }
    }
  }
}
