@import "../vars.scss";

.opinion-component {
  background-color: #ffffff;
  text-align: center;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  h4 {
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #243e84;
    text-transform: uppercase;
    margin: 0.5em 0em 1.5em 0em;
    max-width: 70%;
    @media ($iphone8plus) {
      max-width: 90%;
    }
    @media ($ipad) {
      font-size: 18px;
      line-height: 22px;
    }
    @media ($display4k) {
      font-size: 48px;
      line-height: 52px;
    }
    &.notmargin {
      margin: 0.5em 0em 0.1em 0em;
    }
  }
  h3 {
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 38px;
    color: #243e84;
    text-transform: uppercase;
    margin: 0.1em 0em 1.5em 0em;
    @media ($ipad) {
      font-size: 25px;
      line-height: 28px;
      margin: 0em 0em 1.5em 0em;
    }
    @media ($display4k) {
      font-size: 64px;
      line-height: 67px;
    }
  }
  iframe {
    &.frame-component {
      min-width: 48%;
      min-height: 317px;
      @media ($display4k) {
        min-height: 717px;
      }
      @media ($ipad) {
        min-width: 80%;
        min-height: 317px;
      }
      @media ($iphone8plus) {
        min-height: 100%;
        min-height: 217px;
      }
    }
  }
  .opinion-card {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5em;
    margin: 0px 0px 3em 0px;
    @media ($ipad) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 0px 1em 0px;
      grid-gap: 0.1em;
    }
    @media ($iphone8plus) {
      max-width: 90%;
    }
    .individual {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1em;
      align-self: stretch;
      @media ($ipad) {
        margin: 1em 0;
      }
      .datos {
        display: flex;

        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.5em;

        .img {
          border-radius: 50%;
          min-height: 40px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          max-width: 40px;
          width: 40px;
          height: 40px;
          @media ($display4k) {
            width: 80px;
            height: 80px;
            max-width: 80px;
            min-width: 80px;
          }
          &.juan {
            background-image: url("../../assets/img/juanluis.png");
          }
          &.luisa {
            background-image: url("../../assets/img/luisa.png");
          }
          &.carlos {
            background-image: url("../../assets/img/carlos.png");
          }
          &.alejandra {
            background-image: url("../../assets/img/alejandra.png");
          }
        }
        p {
          margin-left: 0.5em;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: rgba(0, 0, 0, 0.87);
          @media ($display4k) {
            font-size: 28px;
            line-height: 34px;
            margin: 0.7em;
          }
        }
      }
      p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.6);
        text-align: left;
        margin: 0;
        @media ($display4k) {
          font-size: 28px;
          line-height: 32px;
          margin: 0.5em;
        }
      }
    }
  }
}
