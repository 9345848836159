@import "../vars.scss";
.gracias-component {
  background: url("../../assets/img/boltgracias.png"),
    linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
  background-position: center;
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  margin: 1em;
  @media ($ipad) {
    margin: 1em 0.3em;
  }
  a {
    img {
      margin: 0 0 2em 0;
    }
  }
  h4 {
    font-family: "Gotham-medium";
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    max-width: 38%;
    @media ($ipad) {
      font-size: 24px;
      line-height: 32px;
      max-width: 90%;
    }
  }
}
