@import "../vars.scss";
.first-compo-todavia {
  @media ($ipad) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 0 10% 0;
  }
  .compo-todavia {
    background: linear-gradient(90deg, #243e84 4.79%, #1e9cac 59.86%);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    display: flex;
    min-height: 50vh;
    width: 100%;
    @media ($ipad) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80%;
      border-radius: 16px;
    }
    .compo-class {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      @media ($ipad) {
        width: 100%;
      }
      &.todavia-title {
        h3 {
          font-family: "Gotham-medium";
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 43px;
          text-transform: uppercase;
          color: #ffffff;
          max-width: 62%;
          @media ($display4k) {
            font-size: 72px;
            line-height: 74px;
          }
          @media ($ipad) {
            font-size: 20px;
            line-height: 24px;
            margin-top: 0.5em;
            max-width: 85%;
          }
        }
      }
      &.todavia-simulador {
        h4 {
          font-family: "Gotham-medium";
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          max-width: 60%;
          margin: 0.5em 0 1em 0;
          @media ($display4k) {
            font-size: 50px;
            line-height: 51px;
          }
          @media ($ipad) {
            font-size: 13px;
            line-height: 16px;
            font-family: "Gotham-light";
          }
        }
        a {
          button {
            background: #ffffff;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
              0px 2px 2px rgba(0, 0, 0, 0.24);
            border-radius: 30px;
            padding: 20px 0 15px 0;
            min-width: 456px;
            font-family: "Gotham-medium";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: #243e84;
            @media ($display4k) {
              font-size: 32px;
              line-height: 34px;
              min-width: 656px;
            }
            @media ($ipad) {
              font-size: 16px;
              line-height: 9px;
              min-width: 250px;
            }
          }
        }
      }
    }
  }
}
